<template>
  <div class="col-xs-12 col-sm-6 col-md-4">
    <div class="prminibox promoarea">
      <router-link
        :to="
          promotion.old
            ? `/promotions/${promotion.id}`
            : `/promotions/app/${promotion.id}`
        "
      >
        <div class="boxrou bbgra bbimg">
          <div class="bbimg--bg">
            <img
              v-if="promotion.image && promotion.old"
              :src="promotion | asPromotionImage"
              class="imgr"
            />
            <img
              v-else-if="promotion.image"
              :src="promotion.image"
              class="imgr"
            />
          </div>
          <div class="bbimg-desc">
            <h5>{{ promotion.translates[promotion.default_lang].name }}</h5>
            <h6
              v-if="promotion.translates[promotion.default_lang].duration_info"
            >
              {{ promotion.translates[promotion.default_lang].duration_info }}
            </h6>
          </div>
        </div>
      </router-link>
      <div class="join">
        <div>
          <router-link :to="`/promotions/app/${promotion.id}`">{{
            $t("default.listOfparticipants")
          }}</router-link>
        </div>
        <div>
          <a
            v-if="promotion.translates[promotion.default_lang].statute"
            @click="
              OPEN_POPUP_STATUTE(
                promotion.translates[promotion.default_lang].statute
              )
            "
            >{{ $t("default.statute") }}</a
          >
          <span v-else>{{ $t("default.noPromotionRules") }}</span>
          <a
            v-if="promotion.translates[promotion.default_lang].leaflet_file"
            target="_blank"
            :href="promotion.translates[promotion.default_lang].leaflet_file"
            >&nbsp;|&nbsp;{{ $t("default.leaflet") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OPEN_POPUP_STATUTE } from "../store/mutations.type";
import { mapMutations } from "vuex";
export default {
  props: {
    promotion: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapMutations([OPEN_POPUP_STATUTE]),
  },
};
</script>
