var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main2"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchInput),expression:"searchInput"}],staticClass:"form-control matb20",attrs:{"type":"text","minlength":3,"placeholder":_vm.$t('tables.search')},domProps:{"value":(_vm.searchInput)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchInput=$event.target.value},_vm.debouncedSearch]}})]),_c('div',{staticClass:"col-xs-12 col-md-8"},[_c('div',{staticClass:"flexWrap"},[_c('div',{staticClass:"abtn--c4",staticStyle:{"margin-right":"16px","cursor":"pointer"},on:{"click":_vm.download2}},[_vm._v(" "+_vm._s(_vm.$t("default.download"))+" ")]),_c('pagination',{attrs:{"total-page":_vm.pages,"size":"md","align":"right","boundary-links":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12"},[_c('Table',{directives:[{name:"show",rawName:"v-show",value:(_vm.myPpClientsWithProtocols),expression:"myPpClientsWithProtocols"}],staticClass:"rwdtable header--myclients",staticStyle:{"width":"100%"},attrs:{"items":_vm.myPpClientsWithProtocols,"headers":_vm.headers,"fields":_vm.fields,"search":_vm.search,"page":_vm.page,"itemsPerPage":_vm.itemsPerPage,"pages":_vm.pages,"fieldTypes":{
            client_ix: 'numeric',
            client_nip: 'numeric',
            client_firmname: 'text',
            client_branch: 'numeric',
            client_branch_ph: 'text',
          }},on:{"update:page":function($event){_vm.page=$event},"update:pages":function($event){_vm.pages=$event},"tr:clicked":function($event){return _vm.OPEN_POPUP_CLIENT($event.client_ix)}}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12"},[_c('pagination',{attrs:{"total-page":_vm.pages,"size":"md","align":"right","boundary-links":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }