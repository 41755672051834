<template>
  <div v-if="promotion" class="row equal">
    <div class="col-xs-12 col-md-6 col-lg-6" style="z-index: 1">
      <div class="vercenter">
        <h2 class="hs1">{{ promotion.name }}</h2>
        <p></p>
        <p class="small matb10" :title="promotion.ended_at">
          {{ $t("default.endOfpromotion") }} {{ timeToEnd | asCounter }}.
        </p>
        <a
          href="#"
          v-if="promotion.contents.statute"
          @click="OPEN_POPUP_STATUTE(promotion.contents.statute)"
          class="abtn--c5 matb15"
          >{{ $t("default.seePromotionRegulations") }}</a
        >
      </div>
    </div>
    <div class="col-xs-12 col-md-6 col-lg-5 col-lg-push-1 pat10">
      <div class="imgh3xx">
        <img :src="promotion | asPromotionImage" class="imgr" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { counter } from "../common/mixins";
import { OPEN_POPUP_STATUTE } from "../store/mutations.type";

export default {
  mixins: [counter],
  computed: {
    ...mapGetters(["promotion"]),
    timeToEnd() {
      return this.countTo(this.promotion.ended_at);
    },
  },
  created() {
    this.countEvery(60);
  },
  methods: {
    ...mapMutations([OPEN_POPUP_STATUTE]),
  },
};
</script>
