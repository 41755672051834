<template>
  <div v-if="myInfo" class="row">
    <div class="col-xs-12 col-md-6">
      <div class="vercenter">
        <h2 v-if="myInfo.branch_account_type == 'PH'" class="hs1">
          {{ $t("home.hello") }}
        </h2>
        <h2 v-else-if="myInfo.branch_account_type == 'DF'" class="hs1">
          {{ $t("home.hello2") }}
        </h2>
        <h2 v-else-if="myInfo.branch_account_type == 'DRG'" class="hs1">
          {{ $t("home.hello3") }}
        </h2>
        <h2 v-else class="hs1">
          {{ $t("home.hello4") }} {{ myInfo.branch_account_type }}
        </h2>
      </div>
    </div>
    <div class="col-xs-12 col-md-6 pat50">
      <div v-if="myInfo.branch_account_type == 'DF' && myBonuses">
        <div class="branchrankbox">
          <router-link to="/my-bonuses" :title="$t('home.bonusBranch')">
            <h4>
              {{ $t("home.bonusBranch") }}
              <small>{{ $t("default.more") }}</small>
            </h4>
            <svg
              class="circle-chart"
              viewBox="0 0 45 45"
              width="380"
              height="380"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                class="circle-chart__background"
                stroke="#D7F2F2"
                stroke-width="3.9"
                fill="none"
                cx="50%"
                cy="50%"
                r="15.91549431"
              />
              <circle
                class="circle-chart__circle"
                stroke="#52ae30"
                stroke-width="4"
                :stroke-dasharray="
                  floorFigure(myBonuses.realisation.percent) + ',100'
                "
                stroke-linecap="round"
                fill="none"
                cx="50%"
                cy="50%"
                r="15.91549431"
              />
              <text
                class="circle-chart__percent"
                fill="#FFFFFF"
                stroke-width="0"
                x="22"
                y="22"
                alignment-baseline="central"
                text-anchor="middle"
                font-size="8"
                transform="rotate(180 22 22.5)"
              >
                {{ floorFigure(myBonuses.realisation.percent) }}&nbsp;%
              </text>
            </svg>
          </router-link>
        </div>
      </div>
      <!--
	<div v-else-if='myInfo.branch_account_type=="DRG"'>
                <div class="branchrankboxdrg">
                    <router-link to="/my-bonuses-branches" :title="$t('home.bonusBranch')">
                        <h4>{{ $t("home.bonusBranch") }} <small>{{ $t("default.more") }}</small></h4>
                    </router-link>
                </div>
            </div>
			
      <div v-else class="vercenter">
        <p class="fs1">{{ $t("home.info") }}</p>
      </div>
	-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isPopupAccountOpen", "myInfo", "myBonuses"]),
  },
  methods: {
    floorFigure(val, decimals) {
      if (!decimals) decimals = 1;
      var d = Math.pow(10, decimals);
      return (parseInt(val.replace(",", ".") * d) / d).toFixed(decimals);
    },
  },
};
</script>
