<template>
  <div>
    <modal
      v-if="isOpen"
      v-model="isOpen"
      title="Client"
      @show="onShow"
      ref="modal"
      id="modal-client"
      size="lg"
      auto-focus
    >
      <div slot="title">
        {{ $t("client.client") }} {{ popupClient.client_ix }}
      </div>
      <h4>
        <strong>{{ popupClient.client_firmname }}</strong>
      </h4>
      <hr />
      <div v-if="ppClient" class="row">
        <div class="col-xs-12 col-md-6">
          <p>
            {{ $t("client.info") }} <strong>{{ $t("client.info2") }}</strong>
          </p>
          <p v-if="userEmail">{{ $t("client.email") }}: {{ userEmail }}</p>
          <p v-if="popupClient.client_maxserwis == '1'">
            {{ $t("client.info3") }}
            <strong class="color-maxserwis">ma<span>X</span>serwis</strong>
          </p>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-xs-12 col-md-6">
          <!-- <h5>{{ $t("client.withoutPA") }}</h5> -->
          <p v-if="userEmail">{{ $t("client.email") }}: {{ userEmail }}</p>
        </div>
      </div>
      <div v-if="myProtocols.length > 0" class="row">
        <div class="col-xs-12" style="margin: 20px 0">
          <h3 style="margin-bottom: 10px">{{ $t("tables.protocols") }}</h3>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{ $t("tables.promotion") }}</th>
                <th>{{ $t("tables.prizeName") }}</th>
                <th>{{ $t("tables.status") }}</th>
                <th>{{ $t("tables.lastUpdate") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="protocol in myProtocols" :key="protocol.protocol_id">
                <td>{{ protocol.prize_campaign.pl }}</td>
                <td>{{ protocol.prize_name.pl }}</td>
                <td>{{ $t("client.protocol.2") }}</td>
                <td>{{ protocol.updated_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- 
        <div class="col-xs-12 text-center">
          <p>
            <a @click="openUrl">{{ $t("client.login") }}</a>
          </p>
        </div>
		-->
      </div>
      <div slot="footer">
        <btn @click="isOpen = false">{{ $t("default.close") }}</btn>
      </div>
    </modal>
  </div>
</template>

<script>
//import _ from 'lodash'
import { mapGetters } from "vuex";
import { AP_PROMOTIONS_AUTH_ADDRESS } from "../common/config";
import { CLOSE_POPUP_CLIENT } from "../store/mutations.type";
import _ from "lodash";

export default {
  methods: {
    onShow() {
      // console.log('client')
    },
    openUrl() {
      window.open(this.loginLink, "_blank");
    },
  },
  computed: {
    ...mapGetters([
      "isPopupClientOpen",
      "popupClient",
      "ppClients",
      "protocols",
      "app_users",
      "promotions_users",
    ]),
    isOpen: {
      get() {
        return this.isPopupClientOpen;
      },
      set() {
        this.$store.commit(CLOSE_POPUP_CLIENT);
      },
    },
    myProtocols() {
      return this.protocols[this.popupClient.client_ix] || [];
    },
    loginLink() {
      return (
        AP_PROMOTIONS_AUTH_ADDRESS + "?usertoken=" + this.ppClient.token + "&"
      );
    },
    ppClient() {
      return _.find(
        this.ppClients,
        (ppClient) => ppClient.ix == this.popupClient.client_ix
      );
    },
    userEmail() {
      return (
        (this.promotions_users[this.popupClient.client_ix] &&
          this.promotions_users[this.popupClient.client_ix].email) ||
        (this.app_users[this.popupClient.client_ix] &&
          this.app_users[this.popupClient.client_ix].email) ||
        null
      );
    },
  },
};
</script>
