<style>
table.initial tbody tr {
  cursor: initial !important;
}
table.header--promotion.initial thead tr th:nth-child(1) {
  width: 100px;
}
table.header--promotion.initial thead tr th:nth-child(2) {
  width: auto;
}
table.header--promotion.initial thead tr th:nth-child(3) {
  width: auto;
}
table.header--promotion.initial thead tr th:nth-child(4) {
  width: auto;
}
table.header--promotion.initial thead tr th:nth-child(5) {
  width: auto;
}
table.header--promotion.initial thead tr th:nth-child(6) {
  width: auto;
}
</style>

<template>
  <main class="main2">
    <div v-if="loading == 1" class="container loader-wrapper">
      <div class="row">
        <div class="col-xs-12 text-center">
          <p class="loading2">{{ $t("default.loading") }}</p>
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading == 2" class="container">
      <div v-if="promotionClients.length" class="row">
        <div class="col-xs-12 col-md-4">
          <input
            type="text"
            class="form-control matb20"
            v-model="search"
            :placeholder="$t('tables.search')"
          />
        </div>
        <div class="col-xs-12 col-md-8">
          <div class="flexWrap">
            <div
              @click="download2"
              class="abtn--c4"
              style="margin-right: 16px; cursor: pointer"
            >
              {{ $t("default.download") }}
            </div>
            <pagination
              v-model="page"
              :total-page="pages"
              size="md"
              align="right"
              boundary-links
            />
          </div>
        </div>
      </div>

      <div v-if="promotionClients.length" class="row">
        <div class="col-xs-12">
          <Table
            class="rwdtable header--promotion initial"
            style="width: 100%"
            :items="promotionClients"
            :headers="headers"
            :fields="fields"
            :search="search"
            :page.sync="page"
            :itemsPerPage="itemsPerPage"
            :pages.sync="pages"
          />
          <!-- @tr:clicked="OPEN_POPUP_CLIENT($event.client_ix)" -->
        </div>
      </div>

      <h4 v-if="!promotionClients.length" class="loading text-center">
        {{ $t("default.noData") }}
      </h4>
    </div>

    <PopupPromotionClient />
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Table from "../components/Table";
import PopupPromotionClient from "@/components/PopupPromotionClient";
import { FETCH_PROMOTION } from "../store/actions.type";
import { OPEN_POPUP_CLIENT, SET_APP_PROMOTION } from "../store/mutations.type";
import exportFromJSON from "export-from-json";
import xlsx from "json-as-xlsx";

export default {
  components: {
    Table,
    PopupPromotionClient,
  },
  data() {
    return {
      loading: false,
      noConnect: false,
      fields: [
        "client_ix",
        "client_firmname",
        "client_amount",
        "client_points",
        "client_position",
        "client_branch",
        "client_branch_ph",
      ],
      search: "",
      itemsPerPage: 25,
      page: 1,
      pages: 1,
      promotion: null,
      promotionClients: [],
    };
  },
  computed: {
    ...mapGetters([
      "myClients",
      "isPopupClientOpen",
      "app_promotions",
      "app_promotions_archive",
      "app_promotion_clients",
    ]),
    headers() {
      return [
        this.$i18n.t("tables.customerNumber"),
        this.$i18n.t("tables.name"),
        this.$i18n.t("tables.purchases"),
        this.$i18n.t("tables.points"),
        this.$i18n.t("tables.position"),
        this.$i18n.t("tables.branch"),
        this.$i18n.t("tables.ph"),
      ];
    },
    appPromotionsAll() {
      return this.app_promotions.concat(this.app_promotions_archive);
    },
  },
  created() {
    this.loading = 1;
    this.load();
  },
  beforeDestroy() {
    this.SET_APP_PROMOTION(null);
  },

  methods: {
    ...mapActions([
      FETCH_PROMOTION,
      "FETCH_NEW_PROMOTIONS",
      "FETCH_NEW_CLIENTS_PROMOTION",
    ]),
    ...mapMutations([
      OPEN_POPUP_CLIENT,
      SET_APP_PROMOTION,
      "SET_APP_PROMOTION",
    ]),
    async load() {
      await this.FETCH_NEW_CLIENTS_PROMOTION(this.$route.params.id);
      if (this.app_promotions.length === 0) await this.FETCH_NEW_PROMOTIONS();

      if (this.appPromotionsAll.length > 0) {
        const isPromotion = this.appPromotionsAll.find(
          (item) => Number(item.id) === Number(this.$route.params.id)
        );
        if (isPromotion) {
          const app_promotion_clients_ix =
            this.app_promotion_clients?.clients?.map((item) => item.client_ix);
          const clients = app_promotion_clients_ix
            ? this.myClients.filter((client) =>
                app_promotion_clients_ix.includes(client.client_ix)
              )
            : [];
          this.promotionClients = this.app_promotion_clients?.clients
            ? this.app_promotion_clients.clients.map((t1) => ({
                ...t1,
                ...clients.find((t2) => t2.client_ix === t1.client_ix),
              }))
            : [];
          this.promotion = isPromotion;
          this.SET_APP_PROMOTION(isPromotion);
        }
        this.loading = 2;
      }
    },
    download() {
      const reduceItems = this.fields.reduce((accumulator, element, index) => {
        return { ...accumulator, [element]: this.headers[index] };
      }, {});
      const data = this.promotionClients;
      const fields = reduceItems;
      const fileName = `${this.promotion.translates[
        this.promotion.default_lang
      ].name.replace(/ /g, "_")}_${this.promotion.active_from.split(" ")[0]}_${
        this.promotion.active_to.split(" ")[0]
      }`;
      const exportType = exportFromJSON.types.csv;

      exportFromJSON({ data, fileName, fields, exportType });
    },
    download2() {
      let data = [
        {
          sheet: ``,
          columns: [
            {
              label: this.$i18n.t("tables.customerNumber"),
              value: "client_ix",
            },
            { label: this.$i18n.t("tables.name"), value: "client_firmname" },
            {
              label: this.$i18n.t("tables.purchases"),
              value: "client_amount",
              // eslint-disable-next-line no-useless-escape
              format: "#,##0.00\ [$zł-415]",
            },
            { label: this.$i18n.t("tables.points"), value: "client_points" },
            {
              label: this.$i18n.t("tables.position"),
              value: "client_position",
            },
            { label: this.$i18n.t("tables.branch"), value: "client_branch" },
            { label: this.$i18n.t("tables.ph"), value: "client_branch_ph" },
          ],
          content: this.promotionClients,
        },
      ];

      let settings = {
        fileName: `${this.promotion.translates[
          this.promotion.default_lang
        ].name.replace(/ /g, "_")}_${
          this.promotion.active_from.split(" ")[0]
        }_${this.promotion.active_to.split(" ")[0]}`,
        extraLength: 3,
        writeMode: "writeFile",
        writeOptions: {},
      };
      xlsx(data, settings);
    },
  },
};
</script>
