import apiApPromotionsNew from "@/api/ap-promotions-new";
import apiApPromotions from "@/api/ap-promotions";
import apiFilia from "@/api/api-filia";
import apiApexpert from "@/api/api-apexpert";
import apiMaxGearSky from "@/api/api-maxgearsky";
import apiViptotrip from "@/api/api-viptotrip";
import apilnmwarsztat from "@/api/api-lnmwarsztat";
import apilnmsklep from "@/api/api-lnmsklep";
import apiWlm from "@/api/api-wlm";
import apiSpn3 from "@/api/api-spn3";
import { moveIdToArrayItem } from "../common/functions";
import {
  FETCH_INIT,
  FETCH_PROMOTIONS,
  FETCH_MY_CLIENTS,
  FETCH_MY_INFO,
  FETCH_PROMOTION,
  FETCH_PP_CLIENTS,
  FETCH_PROTOCOL_CLIENTS,
  FETCH_MAXGEARSKY,
  FETCH_APEXPERT,
  FETCH_VIPTOTRIP,
  FETCH_wlm,
  FETCH_spn3,
  FETCH_lnmwarsztat,
  FETCH_lnmsklep,
  FETCH_MY_BONUSES,
  FETCH_MY_BONUSES_DRG,
} from "./actions.type";
import {
  SET_INIT,
  SET_PROMOTION,
  SET_MY_CLIENTS,
  SET_MY_CLIENTS_LOADING,
  SET_MY_INFO,
  SET_PP_CLIENTS,
  SET_MAXGEARSKY,
  SET_APEXPERT,
  SET_VIPTOTRIP,
  SET_wlm,
  SET_spn3,
  SET_lnmwarsztat,
  SET_lnmsklep,
  SET_MY_BONUSES,
  SET_MY_BONUSES_DRG,
  SET_APP_PROTOCOLS,
} from "./mutations.type";
//import {FILIA_API_ADDRESS} from '../common/config'

// Włącz, jeśli ma czytać lokalne pliki API.
let readLocal = process.env.NODE_ENV !== "production";

export default {
  async [FETCH_INIT](context) {
    if (readLocal) {
      // DANE STATYCZNE
      const response = require("../data/init.json");

      await new Promise((resolve) => setTimeout(resolve, 100));

      context.commit(SET_INIT, response);
    } else {
      // AXIOS
      let response = undefined;
      // Catch AXIOS error
      try {
        response = await apiFilia.get("init", {});
      } catch (e) {
        // If not authorized
        window.location = "/login.php";
      }
      context.commit(SET_INIT, response.data);
    }
  },
  async ["FETCH_PROMOTIONS_USERS"](context) {
    if (readLocal) {
      // DANE STATYCZNE
      const response = require("../data/promoUsers.json");

      await new Promise((resolve) => setTimeout(resolve, 100)); // wait
      context.commit("SET_PROMOTIONS_USERS", response);
    } else {
      // AXIOS
      let response = undefined;
      // Catch AXIOS error
      try {
        response = await apiApPromotionsNew.get("apfilia/user/register");
        context.commit("SET_PROMOTIONS_USERS", response.data);
      } catch (e) {
        throw e.message;
      }
    }
  },
  async ["FETCH_NEW_PROMOTIONS"](context) {
    if (readLocal) {
      // DANE STATYCZNE
      const response = require("../data/extApp.json");

      await new Promise((resolve) => setTimeout(resolve, 100)); // wait
      for (const [key, value] of Object.entries(response)) {
        value.id = key;
      }
      context.commit("SET_APP_PROMOTIONS", Object.values(response));
    } else {
      // AXIOS
      let response = undefined;
      // Catch AXIOS error
      try {
        response = await apiApPromotionsNew.get("apfilia/promotion", {
          params: {
            groups_id: context.getters.myInfo.branch_groups
              .map((item) => `'${item}'`)
              .join(","),
          },
        });
        for (const [key, value] of Object.entries(response.data)) {
          value.id = key;
        }
        context.commit("SET_APP_PROMOTIONS", Object.values(response.data));
      } catch (e) {
        throw e.message;
      }
    }
  },
  async ["FETCH_NEW_PROMOTIONS_ARCHIVE"](context) {
    if (readLocal) {
      // DANE STATYCZNE
      const response = require("../data/extAppArch.json");

      await new Promise((resolve) => setTimeout(resolve, 100)); // wait
      for (const [key, value] of Object.entries(response)) {
        value.id = key;
      }
      context.commit("SET_APP_PROMOTIONS_ARCHIVE", Object.values(response));
    } else {
      // AXIOS
      let response = undefined;
      // Catch AXIOS error
      try {
        response = await apiApPromotionsNew.get("apfilia/promotion", {
          params: {
            groups_id: context.getters.myInfo.branch_groups
              .map((item) => `'${item}'`)
              .join(","),
            show: "archive",
            orderBy: "desc",
          },
        });
        for (const [key, value] of Object.entries(response.data)) {
          value.id = key;
        }
        context.commit(
          "SET_APP_PROMOTIONS_ARCHIVE",
          Object.values(response.data)
        );
      } catch (e) {
        throw e.message;
      }
    }
  },
  async ["FETCH_APP_USERS"](context) {
    if (readLocal) {
      // DANE STATYCZNE
      const response = require("../data/GetMyClients-app.json");

      await new Promise((resolve) => setTimeout(resolve, 100)); // wait

      context.commit("SET_APP_USERS", response);
    } else {
      // AXIOS
      let response = undefined;
      // Catch AXIOS error
      try {
        response = await apiApPromotionsNew.get("apfilia/user");
        context.commit("SET_APP_USERS", response.data);
      } catch (e) {
        throw e.message;
      }
    }
  },
  async ["FETCH_NEW_CLIENTS_PROMOTION"](context, id) {
    if (readLocal) {
      // DANE STATYCZNE
      const response = require("../data/extAppClients.json");

      await new Promise((resolve) => setTimeout(resolve, 100)); // wait

      context.commit("SET_APP_PROMOTION_CLIENTS", response);
    } else {
      // AXIOS
      let response = undefined;
      // Catch AXIOS error
      try {
        response = await apiFilia.get("GetPromotionClients?promotionId=" + id);
        if (response.data.ErrorMessage) {
          context.commit("SET_APP_PROMOTION_CLIENTS", []);
        } else {
          context.commit("SET_APP_PROMOTION_CLIENTS", response.data);
        }
      } catch (e) {
        throw e.message;
      }
    }
  },

  async [FETCH_PROMOTIONS]() {
    let response = undefined;

    // Catch AXIOS error
    try {
      response = await apiApPromotions.post("", {
        ...apiApPromotions.defaults.data,
        module: "promotions_list",
      });
    } catch (e) {
      throw e.message;
    }

    // Catch API error
    if (response.data.error) {
      throw response.data.info;
    }

    return moveIdToArrayItem(response.data.promotions);
  },

  // czytam promocję
  async [FETCH_PROMOTION](context, id) {
    let response = undefined;
    //console.log(context.getters.myClientsIx)
    // Catch AXIOS error
    try {
      response = await apiApPromotions.post("", {
        ...apiApPromotions.defaults.data,
        module: "promotion",
        promotion: id,
        clients: context.getters.myClientsIx.join(";"),
      });
    } catch (e) {
      throw e.message;
    }

    // Catch API error
    if (response.data.error) {
      throw response.data.info;
    }
    const promotion = response.data.promotions[id];

    promotion.users_registered = moveIdToArrayItem(promotion.users_registered);

    context.commit(SET_PROMOTION, promotion);
  },

  // czytam listę moich klientów z API FILII
  async [FETCH_MY_CLIENTS](context) {
    context.commit(SET_MY_CLIENTS_LOADING, true);

    //console.log(context.getters.myCompanyCurrent)

    if (readLocal) {
      // DANE STATYCZNE
      const myClients = require("../data/GetMyClients.json");

      await new Promise((resolve) => setTimeout(resolve, 100)); // wait

      context.commit(SET_MY_CLIENTS, myClients);
      context.commit(SET_MY_CLIENTS_LOADING, false);
    } else {
      // AXIOS
      let response = undefined;
      // Catch AXIOS error
      try {
        response = await apiFilia.get("GetMyClients", {
          timeout: 0,
          params: {
            company: context.getters.myCompanyCurrent,
          },
        });
      } catch (e) {
        throw e.message;
      }
      context.commit(SET_MY_CLIENTS, response.data);
      context.commit(SET_MY_CLIENTS_LOADING, false);
    }

    //=================================================
    /*
			let myClients = undefined;
			try {
				myClients = await fetch(
					FILIA_API_ADDRESS+"GetMyClients?login=" + context.getters.init.myLogin, 
					{
						"method":"GET", 
						// "mode":"no-cors", 
						// "credentials": "include", 
						headers: {
							'Content-type': 'application/json'
						}
					}
				)
			} catch (e) {
				throw e.message
			}
			// Catch API error
			console.log('!!! myClients: ', myClients.json())
			context.commit(SET_MY_CLIENTS, myClients.json())
		*/
  },
  async [FETCH_PROTOCOL_CLIENTS](context) {
    if (readLocal) {
      // DANE STATYCZNE
      const response = require("../data/extAppProtocols.json");

      await new Promise((resolve) => setTimeout(resolve, 100)); // wait

      context.commit(SET_APP_PROTOCOLS, Object.values(response));
    } else {
      // AXIOS
      let response = undefined;
      // Catch AXIOS error
      try {
        response = await apiApPromotionsNew.get(
          "apfilia/protocol?protocol_status_id=2&orderBy=generated_at&orderBy=desc"
        );
        context.commit(SET_APP_PROTOCOLS, Object.values(response.data));
      } catch (e) {
        throw e.message;
      }
    }
  },
  // czytam listę klientów platformy promocji
  async [FETCH_PP_CLIENTS](context) {
    let response = undefined;

    // Catch AXIOS error
    try {
      response = await apiApPromotions.post("", {
        ...apiApPromotions.defaults.data,
        module: "clients_default",
        clients: context.getters.myClientsIx.join(";"),
      });
    } catch (e) {
      throw e.message;
    }

    // Catch API error
    if (response.data.error) {
      throw response.data.info;
    }
    const ppClients = moveIdToArrayItem(response.data.users);
    context.commit(SET_PP_CLIENTS, ppClients);
  },

  async [FETCH_MY_INFO](context) {
    if (readLocal) {
      // DANE STATYCZNE
      const myInfo = require("../data/GetMyInfo.json");

      await new Promise((resolve) => setTimeout(resolve, 100)); // wait

      context.commit(SET_MY_INFO, myInfo);
    } else {
      // AXIOS
      let response = undefined;
      // Catch AXIOS error
      try {
        response = await apiFilia.get("GetMyInfo", {});
      } catch (e) {
        throw e.message;
      }
      context.commit(SET_MY_INFO, response.data);
    }

    //===========================================

    /*
	let response = undefined;
	try {
		response = await fetch(
			FILIA_API_ADDRESS+"GetMyInfo?login=" + context.getters.init.myLogin, 
			{
				"method":"GET", 
				//"mode":"no-cors", 
				//"credentials": "include"
				headers: {
					'Content-type': 'application/json'
				}
			}
		)
	} catch (e) {
		throw e.message
	}
	// Catch API error
	// console.log('fetchMyInfo: ',response.json())
	context.commit(SET_MY_INFO, response.json())
*/

    /*
		let response = undefined;
		
		// Catch AXIOS error
		try {
			response = await fetch(
				"https://platformafilieapi-intranetap.msappproxy.net/api/GetMyInfo?login=youngmedia@autopartner.com", 
				{
					"method":"GET", 
					"mode":"no-cors",
					"credentials": "include",
					headers: {
						'Accept': 'application/json',
						'Cookie': 'AzureAppProxyAnalyticCookie_83de1e78-00e9-4bc1-a3d8-d4140a449924_1.3=3|tUL+mdLBXvMqELK/CVKXKCG8wFbWWY0KrU5OfLjOhe8+CLyv80f98uHA9v1wmunDaOuUahEr4rAoKMQgZv/RDTJwP+h71WG5Enp2nDMjzk4LemszC+6oRrk2LsK9kC61Is/VIcLumI+WiAudPqhPWw==; AzureAppProxyAccessCookie_83de1e78-00e9-4bc1-a3d8-d4140a449924_1.3=3|TCaqSTPFzXaWmqeL03NBxhf1OPOw6OdRHMZyrQ0UVVN0xoFKE+RT2aske2koBEmXYDO3Uc3L2cCsPjNP8WcCGH3dTMGMaBzas7CLkAKoGb9UurAsLV7+6yrlu/wnad5Fldk0Mu3MMJU/0cCYJd0++2qDye8TFzxAee3tmxWwZzTXSJhoiP2lqqPx2U2pmGWNywnf1XWMdOJh1Xwsmw7tiVKHc+fi+iKFEzLPFiom3okORpeEOxR5JJl6UdDGLiHhYsY+PbYU+ML3m8NF77+7hXlZXWxv40Ho9SfikyhkjQVONFoi0S1eVxnw4unPR3rFcxLgfeu1WZbWx0ngRg0yNHYd9iGswIvpdLoU439qOwHIvdnxkelsTLIdqhYbTGGzdJ94I5So2p6kauo6HNy3WHqUUMgKKytjpueSA+9ox6RTh9ZP/3x7jUJQvab3PjE+KgMrqH9TCZxtSFfHAZVu3w=='
					}
				}
			)
		} catch (e) {
			throw e.message
		}
		
		// Catch API error
		console.log('fetchMyInfo: ',response.json())
		const myInfo = '';
*/
  },

  async [FETCH_MY_BONUSES](context) {
    if (readLocal) {
      // DANE STATYCZNE
      const myBonuses = require("../data/GetBranchesRank.json");

      await new Promise((resolve) => setTimeout(resolve, 100)); // wait

      context.commit(SET_MY_BONUSES, myBonuses);
    } else {
      // AXIOS
      let response = undefined;
      // Catch AXIOS error
      try {
        response = await apiFilia.get("GetBranchesRank", {});
      } catch (e) {
        throw e.message;
      }
      context.commit(SET_MY_BONUSES, response.data);
    }
  },

  async [FETCH_MY_BONUSES_DRG](context) {
    if (readLocal) {
      // DANE STATYCZNE
      const myBonusesDrg = require("../data/GetBranchesRankDrg.json");

      await new Promise((resolve) => setTimeout(resolve, 100)); // wait

      context.commit(SET_MY_BONUSES_DRG, myBonusesDrg);
    } else {
      // AXIOS
      let response = undefined;
      // Catch AXIOS error
      try {
        response = await apiFilia.get("GetBranchesRankDrg", {});
      } catch (e) {
        throw e.message;
      }
      context.commit(SET_MY_BONUSES_DRG, response.data);
    }
  },

  // czytam promocję zewnętrzną MAXGEAR SKY IS THE LIMIT
  async [FETCH_MAXGEARSKY](context) {
    // AXIOS
    let response = undefined;
    // Catch AXIOS error
    try {
      response = await apiMaxGearSky.post("realisations", {
        token: "SkyLimit2022",
        clients: context.getters.myClientsIx,
        //clients: context.getters.myClientsIx.join(';')
      });
      /*
			response = await apiApexpert.get("index.php?a=filia", {
			})
*/
    } catch (e) {
      throw e.message;
    }
    context.commit(SET_MAXGEARSKY, response.data);
    //context.commit(SET_MY_CLIENTS_LOADING, false)
  },

  // czytam promocję zewnętrzną AP EXPERT
  async [FETCH_APEXPERT](context) {
    // AXIOS
    let response = undefined;
    // Catch AXIOS error
    try {
      response = await apiApexpert.post("filia", {
        token: context.getters.token,
        clients: context.getters.myClientsIx,
        //clients: context.getters.myClientsIx.join(';')
      });
      // response = await apiApexpert.post('index.php', {
      // 	...apiApPromotions.defaults.data,
      // 	module: 'filia',
      // 	clients: context.getters.myClientsIx
      // 	//clients: context.getters.myClientsIx.join(';')
      // })
      /*
			response = await apiApexpert.get("index.php?a=filia", {
			})
*/
    } catch (e) {
      throw e.message;
    }
    context.commit(SET_APEXPERT, response.data);
    //context.commit(SET_MY_CLIENTS_LOADING, false)
  },

  async [FETCH_wlm](context) {
    // AXIOS
    let response = undefined;
    // Catch AXIOS error
    try {
      response = await apiWlm.post("", {
        token: context.getters.token,
        clients: context.getters.myClientsIx,
        //clients: context.getters.myClientsIx.join(';')
      });
      // response = await apiApexpert.post('index.php', {
      // 	...apiApPromotions.defaults.data,
      // 	module: 'filia',
      // 	clients: context.getters.myClientsIx
      // 	//clients: context.getters.myClientsIx.join(';')
      // })
      /*
			response = await apiApexpert.get("index.php?a=filia", {
			})
*/
    } catch (e) {
      throw e.message;
    }
    context.commit(SET_wlm, response.data);
    //context.commit(SET_MY_CLIENTS_LOADING, false)
  },
  async [FETCH_spn3](context) {
    // AXIOS
    let response = undefined;
    // Catch AXIOS error
    try {
      response = await apiSpn3.post("", {
        token: context.getters.token,
        clients: context.getters.myClientsIx,
        //clients: context.getters.myClientsIx.join(';')
      });
      // response = await apiApexpert.post('index.php', {
      // 	...apiApPromotions.defaults.data,
      // 	module: 'filia',
      // 	clients: context.getters.myClientsIx
      // 	//clients: context.getters.myClientsIx.join(';')
      // })
      /*
			response = await apiApexpert.get("index.php?a=filia", {
			})
*/
    } catch (e) {
      throw e.message;
    }
    context.commit(SET_spn3, response.data);
    //context.commit(SET_MY_CLIENTS_LOADING, false)
  },

  // czytam promocję zewnętrzną AP EXPERT
  async [FETCH_VIPTOTRIP](context) {
    // AXIOS
    let response = undefined;
    // Catch AXIOS error
    try {
      response = await apiViptotrip.post("index.php", {
        ...apiApPromotions.defaults.data,
        module: "filia",
        clients: context.getters.myClientsIx,
        //clients: context.getters.myClientsIx.join(';')
      });
      /*
			response = await apiViptotrip.get("index.php?a=filia", {
			})
*/
    } catch (e) {
      throw e.message;
    }
    context.commit(SET_VIPTOTRIP, response.data);
    //context.commit(SET_MY_CLIENTS_LOADING, false)
  },
  async [FETCH_lnmwarsztat](context) {
    // AXIOS
    let response = undefined;
    // Catch AXIOS error
    try {
      response = await apilnmwarsztat.post("", {
        ...apiApPromotions.defaults.data,
        module: "filia",
        clients: context.getters.myClientsIx,
        //clients: context.getters.myClientsIx.join(';')
      });
      /*
			response = await apiViptotrip.get("index.php?a=filia", {
			})
*/
    } catch (e) {
      throw e.message;
    }
    context.commit(SET_lnmwarsztat, response.data);
    //context.commit(SET_MY_CLIENTS_LOADING, false)
  },
  async [FETCH_lnmsklep](context) {
    // AXIOS
    let response = undefined;
    // Catch AXIOS error
    try {
      response = await apilnmsklep.post("", {
        ...apiApPromotions.defaults.data,
        module: "filia",
        clients: context.getters.myClientsIx,
        //clients: context.getters.myClientsIx.join(';')
      });
      /*
			response = await apiViptotrip.get("index.php?a=filia", {
			})
*/
    } catch (e) {
      throw e.message;
    }
    context.commit(SET_lnmsklep, response.data);
    //context.commit(SET_MY_CLIENTS_LOADING, false)
  },
};
